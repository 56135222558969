<template>
    <li :class="isFolder ? 'folder_open': 'file'">
        <div>
            <tb-skeleton shape="radius" style="background-color: #dcdcdc; height: 20px; width:100%;" theme="opacity"></tb-skeleton>
        </div>
        <ul v-if="isFolder">
            <tree-node v-for="i in item.children" :key="i.id" :item="i" :depth="depth+1" />
        </ul>
    </li>
</template>

<style scoped>
    .bold {
        font-weight: bold;
    }
    ul {
        padding-left: 10x;
        margin-left: 0px;
    }
    li {
        list-style: none;
        box-sizing: border-box;
        vertical-align: middle;
        position: relative;
    }
    li::before {
        content:'';
        position:absolute;
        top: -5px;
        left: -31px;
        border-left: 2px dashed #a2a5b5;
        width: 1px;
        height: 100%;
    }
    li:last-child::before {
        height: 20px;
    }
    li::after {
        content:'';
        position: absolute;
        border-top: 2px dashed #a2a5b5;
        top: 15px;
        left: -30px;
        width: 30px;
    }
    li div {
        display: flex;
        cursor: pointer;
    }
    li div::before {
        content: ' ';
        width: 26px;
        height: 26px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-right: 2px;
    }
    li.folder>div::before{
        background-image: url('./icons/folder.svg');
    }
    li.folder_open>div::before {
        background-image: url('./icons/folder-open.svg');
    }
    li.file>div::before {
        background-image: url('./icons/file.svg');
    }
    li.file>div {
        cursor: auto;
    }
    li div a {
        color: black;
    }
</style>

<script>
export default {
    name: 'TreeNode',
    props: {
        item: Object,
        depth: Number
    },
    computed: {
        isFolder () {
            return (this.item.children && this.item.children.length);
        },
    }
}
</script>
