<template>
    <li :class="liClass">
        <div v-if="!item.editing" @click="toggle" class="d-flex align-items-center">
            <input type="checkbox" class="custom-checkbox" :checked="item.checked !== false" :disabled="item.checked === null" v-if="selectable" @click.stop @change="check(item)" :data-cy="`check${item.nome}`">
            <span :class="{ bold: isFolder }" :data-cy="item.nome">{{ item.nome }}</span>
            <button class="nao-botao ml-1" v-if="controls" @click.stop="adicionar" :data-cy="`${item.nome}Adicionar`"><file-plus-icon size="16" /></button>
            <button class="nao-botao ml-1" v-if="controls" @click.stop="editar" :data-cy="`${item.nome}Editar`"><edit-icon size="16" /></button>
            <button class="nao-botao ml-1" v-if="controls" @click.stop="remover" :data-cy="`${item.nome}Excluir`"><trash-2-icon size="16" /></button>

        </div>
        <div v-else class="d-flex align-items-center">
            <form @submit.prevent.stop="salvar">
                <input ref="edicao" :class="{ bold: isFolder }" v-model="item.nome" :disabled="saving" required />
                <button v-if="!item.nZoom" type="button" class="btn btn-light ml-1" @click.stop="$emit('escolher-zoom', item)" :data-cy="`${item.nome}Zoom`">Selecionar mapa </button>
                <button v-else type="button" class="btn btn-light ml-1" @click.stop="$emit('escolher-zoom', item)" :data-cy="`${item.nome}Zoom`">Zoom {{String(item.nZoom).padStart(2, '0')}} <repeat-icon size="16" /> </button>
                <button type="submit" class="nao-botao ml-1" :disabled="saving"><save-icon size="16" :data-cy="`${item.nome}Salvar`" /></button>
            </form>
            <button type="button" class="nao-botao ml-1" @click="cancelar" :disabled="saving"><x-icon size="16" :data-cy="`${item.nome}Cancelar`" /></button>
        </div>
        <ul v-if="isFolder" v-show="item.opened" @click.stop>
            <tree-node :controls="controls" :selectable="selectable" v-for="i in item.children" :key="i.id" :item="i" :depth="depth+1" @cancelar-novo="cancelarNovo" @apagar="apagar" @adicionado-novo="adicionadoNovo" @escolher-zoom="(itemZoom) => $emit('escolher-zoom', itemZoom)"/>
        </ul>
    </li>
</template>

<style scoped>
    .bold {
        font-weight: bold;
    }
    ul {
        padding-left: 10x;
        margin-left: 0px;
    }
    li {
        list-style: none;
        box-sizing: border-box;
        vertical-align: middle;
        position: relative;
    }
    li::before {
        content:'';
        position:absolute;
        top: -5px;
        left: -31px;
        border-left: 2px dashed #a2a5b5;
        width: 1px;
        height: 100%;
    }
    li:last-child::before {
        height: 20px;
    }
    li::after {
        content:'';
        position: absolute;
        border-top: 2px dashed #a2a5b5;
        top: 15px;
        left: -30px;
        width: 30px;
    }
    li div {
        display: inline-flex;
        cursor: pointer;
    }
    li div::before {
        content: ' ';
        width: 26px;
        height: 26px;
        background-size: contain;
        margin-right: 2px;
    }
    li.folder>div::before{
        background-image: url('./icons/folder.svg');
        background-repeat: no-repeat;
        min-width: 16px;
    }
    li.folder_open>div::before {
        background-image: url('./icons/folder-open.svg');
        background-repeat: no-repeat;
        min-width: 16px;
    }
    li.file>div::before {
        background-image: url('./icons/file.svg');
        background-repeat: no-repeat;
        min-width: 16px;
    }
    li.file>div {
        cursor: auto;
    }
    li div a {
        color: black;
    }

    .nao-botao {
        border: 0 !important;
        line-height: 27px;
    }

    .custom-checkbox {
        width: 20px;
        height: 20px;
        margin-right: .25rem;
    }

    @media (max-width: 790px) {
        ul {
            font-size: 16px;
        }
        input {
            height: 24px;
            width: 130px;
        }
        .btn.btn-light.ml-1 {
            font-size: 14px;
        }
        form {
            min-width: 280px;
        }
    }

</style>

<script>
    function options (timeout) {
        return {
            timeout: timeout || 2000,
            showProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true
        }
    }
    import axios from 'axios';
    import api from '@/api';
    import { copyProps } from '@/helpers/common';
    import { verifyPermitions } from '@/helpers/auth';

    export default {
        name: 'tree-node',
        props: {
            item: Object,
            depth: Number,
            controls: {
                type: Boolean,
                default: false
            },
            selectable: {
                type: Boolean,
                default: false
            },
        },
        data () {
            return {
                copy: {},
                saving: false,
                criandoItem: false,
            };
        },
        computed: {
            isFolder () {
                return (this.item.children && this.item.children.length);
            },
            liClass () {
                return !this.isFolder        ? 'file'
                       :this.item.opened     ? 'folder_open'
                       :'folder'
            }
        },
        methods: {
            toggle () {
                this.item.opened = !this.item.opened;
            },
            check (item, v) {
                const val = item.checked = (typeof v === 'undefined' ? !item.checked : v);
                if (item.children) {
                    for (let it of item.children) {
                        this.check(it, val === false ? val : null);
                    }
                }
            },
            cancelar () {
                if (!this.item.id) this.$emit('cancelar-novo');
                else {
                    this.item.editing = false;
                    this.item.id = this.copy.id;
                    this.item.nome = this.copy.nome;
                    this.item.idAreaPai = this.copy.idAreaPai;
                    this.item.nZoom = this.copy.nZoom;
                }
            },
            cancelarNovo () {
                this.criandoItem = false;
                this.item.children.pop();
            },
            apagar (id) {
                let idx = this.item.children.findIndex(c => c.id === id);
                if (idx >= 0) {
                    this.item.children.splice(idx, 1);
                }
            },
            async adicionar () {
                if (!(await verifyPermitions.call(this, 'rw_gerenciamentos'))) return;
                if (this.criandoItem) return;
                this.item.opened = true;
                this.item.children.push({
                    children: [],
                    editing: true,
                    id: null,
                    idAreaPai: this.item.id,
                    nome: "",
                    opened: false,
                    nZoom: null
                });
                this.criandoItem = true;
            },
            adicionadoNovo () {
                this.criandoItem = false;
            },
            async editar () {
                if (!(await verifyPermitions.call(this, 'rw_gerenciamentos'))) return;
                this.copy = copyProps(this.item)(['id','nome','idAreaPai', 'nZoom']);
                this.item.editing = true;
                setTimeout(() => this.$refs.edicao.focus());
            },
            salvar () {
                this.saving = true;
                this.$snotify.async('Aguarde...', 'Salvando', () => new Promise((resolve, reject) => {
                    let payload = copyProps(this.item)(['id','nome','idAreaPai', 'nZoom']);
                    let req = payload.id
                        ? axios.put(api.v1.area.update, payload)
                        : axios.post(api.v1.area.create, payload);

                    req.then(res => {
                        this.saving = false;
                        this.item.id = res.data.id
                        this.item.nome = res.data.nome
                        this.item.idAreaPai = res.data.idAreaPai
                        this.item.nZoom = res.data.nZoom
                        this.item.editing = false;

                        if (!payload.id) this.$emit('adicionado-novo');

                        resolve({
                            title: 'Sucesso!',
                            body: 'Área salva',
                            config: options()
                        });
                    }).catch(e => {
                        this.saving = false;
                        reject({
                            title: 'Erro ao salvar',
                            body: e.response.data ? e.response.data.error : e.toString(),
                            config: options(10000)
                        })
                    });
                }));
            },
            async remover () {
                if (!(await verifyPermitions.call(this, 'rd_gerenciamentos'))) return;
                const doDelete = () => {
                    return axios.delete(api.v1.area.destroy, { data: { id: this.item.id } })
                    .then(res => {
                        return res.data.error ? Promise.reject(res.data.error) : res.data;
                    })
                    .catch(reason => {
                        if(reason.response && reason.response.data && reason.response.data.error) {
                            this.$swal.showValidationMessage('Falha ao excluir: ' + (reason.response.data.error));
                        }
                        else this.$swal.showValidationMessage('Falha ao excluir: ' + (reason ? reason.toString() : 'motivo desconhecido.'));
                    })
                };

                // TODO: transformar em função auxiliar para evitar duplicação de código
                this.$swal.fire({
                    title: 'Você tem certeza?',
                    text: "A remoção de uma área é irreversível",
                    type: 'warning',
                    showCancelButton: true,
                    reverseButtons: true,
                    focusCancel: true,
                    confirmButtonColor: '#dc3545',
                    confirmButtonText: 'Remover',
                    cancelButtonText: 'Cancelar',
                    allowOutsideClick: () => !this.$swal.isLoading(),
                    showLoaderOnConfirm: true,
                    preConfirm: doDelete
                }).then(res => {
                    if (res.value) {
                        this.$snotify.success('Área removida', 'Sucesso!');
                        this.$emit('apagar', this.item.id);
                    }
                })
            },
        },
        mounted () {
            if (this.item.editing) this.$refs.edicao.focus();
        }
    }
</script>
